import http from "./httpService";

const { REACT_APP_WEB_API_URL: web_api_url } = process.env;

const multipartHeader = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

export async function addProject(
  teamname,
  mitglieder,
  titel,
  ziel,
  motivation,
  anwendung,
  datensatz,
  vorbereitung,
  dnn,
  evaluation,
  produkt,
  komponenten,
  anleitung,
  probleme,
  potential,
  schwachstelle,
  zukunft,
  video,
  code,
  files,
  submitted,
  updateProgress
) {
  let data = new FormData();
  data.append("teamname", teamname);
  data.append("mitglieder", mitglieder);
  data.append("titel", titel);
  data.append("ziel", ziel);
  data.append("motivation", motivation);
  data.append("anwendung", anwendung);
  data.append("datensatz", datensatz);
  data.append("vorbereitung", vorbereitung);
  data.append("dnn", dnn);
  data.append("evaluation", evaluation);
  data.append("produkt", produkt);
  data.append("komponenten", komponenten);
  data.append("anleitung", anleitung);
  data.append("probleme", probleme);
  data.append("potential", potential);
  data.append("schwachstelle", schwachstelle);
  data.append("zukunft", zukunft);
  data.append("submitted", submitted);

  if (submitted) {
    if (code && code.length > 0) {
      data.append("code", code[0], code[0].name);
    }
    if (video && video.length > 0) {
      data.append("video", video[0], video[0].name);
    }
    if (files) {
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i], files[i].name);
      }
    }
  }

  return await http.post(`${web_api_url}/project`, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      // console.log(percentComplete);
      updateProgress(percentComplete);
    },
  });
}

export async function hasSubmited() {
  return await http.get(`${web_api_url}/has-submit-project`);
}

export async function getProjectByTeam(teamName) {
  return await http.get(`${web_api_url}/project/${teamName}`);
}

export async function getProjectReport(teamName) {
    return await http.get(`${web_api_url}/project-report/${teamName}`);
}

export async function updateProject(
  teamname,
  mitglieder,
  titel,
  ziel,
  motivation,
  anwendung,
  datensatz,
  vorbereitung,
  dnn,
  evaluation,
  produkt,
  komponenten,
  anleitung,
  probleme,
  potential,
  schwachstelle,
  zukunft,
  video,
  code,
  files,
  submitted,
  updateProgress
) {
  let data = new FormData();
  data.append("teamname", teamname);
  data.append("mitglieder", mitglieder);
  data.append("titel", titel);
  data.append("ziel", ziel);
  data.append("motivation", motivation);
  data.append("anwendung", anwendung);
  data.append("datensatz", datensatz);
  data.append("vorbereitung", vorbereitung);
  data.append("dnn", dnn);
  data.append("evaluation", evaluation);
  data.append("produkt", produkt);
  data.append("komponenten", komponenten);
  data.append("anleitung", anleitung);
  data.append("probleme", probleme);
  data.append("potential", potential);
  data.append("schwachstelle", schwachstelle);
  data.append("zukunft", zukunft);
  data.append("submitted", submitted);

  if (submitted) {
    if (code && code.length > 0) {
      data.append("code", code[0], code[0].name);
    }
    if (video && video.length > 0) {
      data.append("video", video[0], video[0].name);
    }
    if (files) {
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i], files[i].name);
      }
    }
  }
  return await http.put(`${web_api_url}/project/${teamname}`, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      // console.log(percentComplete);
      updateProgress(percentComplete);
    },
  });
}

export default {
    addProject,
    hasSubmited,
    getProjectByTeam,
    updateProject,
    getProjectReport,
}
